<template>
  <b-card no-body class="rounded-0">
    <b-card-header class="bg-white font-weight-bold"> <i class="fad fa-comment-exclamation" /> Rezervasyon Notu </b-card-header>
    <b-card-text class="p-2">
      <b-row>
        <b-col cols="12" class="py-2">
          <b-form-textarea rows="5" class="rounded-0" v-model="sepet.rezervasyon_notu" size="lg" />
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api';
export default defineComponent({
  props: {
    sepet: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expo = {};

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
