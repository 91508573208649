<template>
  <div>
    <!-- <p
      v-html="
        $t('bankaOdemeAciklama1', [
          priceFormat(sepet.sepet_toplam.genel_toplam),
          priceFormat(sepet.odeme_tipi != 'hepsi' ? sepet.sepet_toplam.on_odeme_toplam : sepet.sepet_toplam.genel_toplam),
          exchange.banka_kodu,
          sepet.k_no,
        ])
      "
    /> -->

    <!-- <p class="py-4" v-html="$t('bankaOdemeAciklama2', [contact.e_mail])" /> -->

    <validation-provider :rules="{ required: true }" v-slot="validationContext">
      <b-card
        no-body
        v-for="(item, i) in bankalar"
        :key="i"
        class="mt-2 border rounded-0"
        :class="{ 'border-danger': !!validationContext.errors[0] }"
      >
        <b-card-body class="d-flex align-items-center">
          <div class="pr-2">
            <b-form-radio size="lg" v-model="sepet.banka_k_no" :value="item.k_no" :state="getValidationState(validationContext)" />
          </div>
          <div>
            <h6 class="font-weight-bold text-black-50">{{ item.banka_adi }} | {{ item.banka_hesap_turu }}</h6>
            <span class="d-block">{{ item.banka_iban }}</span>
            <span>{{ item.banka_adresi }}</span>
          </div>
        </b-card-body>
      </b-card>
    </validation-provider>
  </div>
</template>

<script>
import store from '@/store';
import { defineComponent, computed, ref, toRefs } from '@vue/composition-api';
import { exChange } from '@/libs/global';
export default defineComponent({
  props: {
    sepet: {
      type: Object,
      reuqired: true,
    },
  },
  setup(props) {
    const expo = {};
    const { sepet } = toRefs(props);
    expo.exchange = computed(() => store.getters.getExchange);
    expo.bankalar = computed(() => store.getters.getBankalar);
    // expo.contact = setting.contact();

    expo.odemeTipi = ref([
      { text: 'Ön Ödeme', value: 'on_odeme' },
      { text: 'Tamamı', value: 'hepsi' },
    ]);

    expo.priceFormat = computed(() => {
      return (val) => {
        return exChange(val, sepet.value.sabit_kur).toFixed(2);
      };
    });

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
