<template>
  <b-card no-body class="rounded-0">
    <b-card-header class="bg-white font-weight-bold d-flex justify-content-between align-items-center">
      <div><i class="fad fa-money-bill-wave" /> Ödeme Bilgisi</div>
      <b-form-group class="rounded-0" size="sm" v-slot="{ ariaDescribedby }">
        <b-form-radio-group
          v-model="sepet.odeme_yontemi"
          :options="odemeYontemi"
          :aria-describedby="ariaDescribedby"
          button-variant="outline-primary"
          name="odeme_yontemi"
          buttons
          size="sm"
          class="rounded-0"
        />
      </b-form-group>
    </b-card-header>
    <b-card-text class="p-2">
      <KrediKartiBilgi v-if="sepet.odeme_yontemi == 'kredi_karti' && odemeVarmi('kredi_karti')" :sepet="sepet" />
      <BankaHesapBilgileri v-else-if="sepet.odeme_yontemi == 'havale_eft' && odemeVarmi('havale_eft')" :sepet="sepet" />
      <OdemeKapida v-else-if="sepet.odeme_yontemi == 'kapida_odeme' && odemeVarmi('kapida_odeme')" :sepet="sepet" />
    </b-card-text>
  </b-card>
</template>

<script>
import store from '@/store';
import { ref, computed, defineComponent, toRefs, watch, onMounted } from '@vue/composition-api';
import BankaHesapBilgileri from './OdemeBankaHesapBilgileri.vue';
import KrediKartiBilgi from './OdemeKrediKartiBilgi.vue';
import OdemeKapida from './OdemeKapida.vue';
export default defineComponent({
  components: { KrediKartiBilgi, BankaHesapBilgileri, OdemeKapida },
  props: {
    sepet: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const expo = {};
    const { sepet } = toRefs(props);

    const odeme = computed(() => store.getters.getOdemeSistemi);
    expo.odemeYontemi = ref([]);

    expo.odemeVarmi = computed(() => {
      return (val) => {
        return expo.odemeYontemi.value.some((x) => x.value == val);
      };
    });

    const handlerOdemeSistemi = async (payload) => {
      expo.odemeYontemi.value = [];
      if (payload != undefined) {
        payload.k_k_odeme == true &&
          expo.odemeYontemi.value.push({
            value: 'kredi_karti',
            text: 'Kredi Kartı',
          });
        payload.havale_eft == true &&
          expo.odemeYontemi.value.push({
            value: 'havale_eft',
            text: 'Havale EFT',
          });

        payload.kapida_odeme == true &&
          expo.odemeYontemi.value.push({
            value: 'kapida_odeme',
            text: 'Kapıda Ödeme',
          });
      }
    };

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };
    watch(
      odeme,
      (val) => {
        handlerOdemeSistemi(val);
      },
      { deep: true, immediate: true }
    );

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
