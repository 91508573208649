<template>
  <div>
    <b-alert :show="true" variant="info" class="text-center">
      <h5>Bilgi</h5>
      <p>Misafir Rezervasyon tarihide Tur,Villa,Transfer,Araç Kiralama vb. giriş esnasında ödeme yapar.</p>
    </b-alert>
  </div>
</template>

<script>
import store from '@/store';
import { defineComponent, computed, ref, toRefs } from '@vue/composition-api';
import { exChange } from '@/libs/global';
export default defineComponent({
  props: {
    sepet: {
      type: Object,
      reuqired: true,
    },
  },
  setup(props) {
    const expo = {};

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
