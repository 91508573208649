<template>
  <b-card no-body class="rounded-0">
    <b-card-header class="bg-white font-weight-bold"> <i class="fad fa-user" /> Misafir Bilgisi </b-card-header>
    <b-card-text class="p-2">
      <b-row>
        <b-col cols="12" md="6">
          <validation-provider name="Ad" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Ad">
              <b-form-input
                class="rounded-0"
                v-model="sepet.misafir.ad"
                size="lg"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <validation-provider name="Soyad" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Soyad">
              <b-form-input
                class="rounded-0"
                v-model="sepet.misafir.soyad"
                size="lg"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="Doğum Tarihi">
            <b-form-input
              class="rounded-0"
              v-model="sepet.misafir.dogum_tarihi"
              v-mask="'##.##.####'"
              placeholder="##.##.####"
              size="lg"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <b-form-group label="TC & Pasaport">
            <b-form-input class="rounded-0" v-model="sepet.misafir.tc_pasaport" size="lg" />
          </b-form-group>
        </b-col>
        <b-col cols="12" md="6">
          <validation-provider name="Telefon" :rules="{ required: true }" v-slot="validationContext">
            <b-form-group label="Telefon">
              <b-form-input
                v-mask="'+## ### ### ## ##'"
                class="rounded-0"
                v-model="sepet.misafir.telefon"
                size="lg"
                placeholder="+## ### ### ## ##"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12" md="6">
          <validation-provider name="E Mail" :rules="{ required: true, email: true }" v-slot="validationContext">
            <b-form-group label="E Mail">
              <b-form-input
                class="rounded-0"
                v-model="sepet.misafir.e_mail"
                placeholder="__@mail.com"
                size="lg"
                :state="getValidationState(validationContext)"
              />
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col cols="12">
          <b-form-group label="Adres">
            <b-form-input class="rounded-0" v-model="sepet.misafir.adres" size="lg" />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-text>
  </b-card>
</template>

<script>
import { computed, defineComponent } from '@vue/composition-api';
export default defineComponent({
  components: {},
  props: {
    sepet: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expo = {};

    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
