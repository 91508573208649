<template>
  <div>
    <b-row>
      <b-col cols="12">
        <validation-provider name="Kart Üstündeki Ad Soyad" :rules="{ required: true }" v-slot="validationContext">
          <b-form-group label="Kart Üstündeki Ad Soyad">
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fad fa-user" />
              </b-input-group-prepend>
              <b-form-input
                v-model="sepet.kart_bilgileri.kart_ad_soyad"
                size="lg"
                class="rounded-0"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-input-group>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col cols="12" class="pt-3">
        <validation-provider name="Kart Numarası" :rules="{ required: true }" v-slot="validationContext">
          <b-form-group label="Kart Numarası">
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fad fa-credit-card" />
              </b-input-group-prepend>
              <b-form-input
                v-model="sepet.kart_bilgileri.kart_numarasi"
                size="lg"
                class="rounded-0"
                v-mask="'#### #### #### ####'"
                placeholder="____ ____ ____ ____"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-input-group>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row class="pt-3">
      <b-col cols="12" md="6">
        <validation-provider name="Kart Ay Yıl" :rules="{ required: true }" v-slot="validationContext">
          <b-form-group label="Kart Ay Yıl">
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fad fa-calendar-week" />
              </b-input-group-prepend>
              <b-form-input
                v-model="sepet.kart_bilgileri.kart_ay_yil"
                size="lg"
                aria-label="First name"
                class="rounded-0"
                v-mask="'##/##'"
                placeholder="__/__"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
              />
            </b-input-group>
          </b-form-group>
        </validation-provider>
      </b-col>

      <b-col cols="12" md="6">
        <validation-provider name="Kart Güvenlik Kodu" :rules="{ required: true }" v-slot="validationContext">
          <b-form-group label="Kart Güvenlik Kodu">
            <b-input-group>
              <b-input-group-prepend is-text>
                <i class="fad fa-user-lock" />
              </b-input-group-prepend>
              <b-form-input
                v-model="sepet.kart_bilgileri.kart_ccv"
                size="lg"
                class="rounded-0"
                v-mask="'###'"
                placeholder="___"
                :state="getValidationState(validationContext)"
                aria-describedby="input-1-live-feedback"
                @focus="handlerCcvFocus(true)"
                @blur="handlerCcvFocus(false)"
              />
              <b-input-group-append is-text>
                <i class="fad fa-info-circle" v-b-tooltip.hover.top="'Kartin arka yüzündeki 3 haneli rakamlardır'" />
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </validation-provider>
      </b-col>
      <b-col class="pt-4">
        <b-form-group>
          <b-input-group>
            <b-form-checkbox switch size="lg" id="checkbox-1" v-model="sepet.kart_bilgileri.kart_3d_guvenlik" :value="true">
              3d Güvenlik
            </b-form-checkbox>
            <b-input-group-append>
              <i
                class="fad fa-info-circle pt-2 pl-2"
                v-b-tooltip.hover.top="'Ödeme işleminizi 3d Secure ile doğrulayın. Güvenliğinizi koruyun.'"
              />
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api';

export default defineComponent({
  props: {
    sepet: {
      type: Object,
    },
  },
  setup() {
    const expo = {};
    expo.kart_ccv = ref(false);
    expo.handlerCcvFocus = (event) => {
      expo.kart_ccv.value = event;
    };
    expo.getValidationState = ({ dirty, validated, valid = null }) => {
      return dirty || validated ? valid : null;
    };
    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
