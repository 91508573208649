import Vue from 'vue';
import moment from 'moment';
import store from '@/store';

export const urun_toplam = (miktar, price) => {
  const total = price * miktar;
  return total;
};

export const kdv_hesapla = (price, kdv_oran) => {
  const fiyat = Number(price);
  const kdv = Number(kdv_oran);
  const total = (fiyat * kdv) / 100;
  return total;
};
export const yuzde_hesapla = (price, yuzde_oran) => {
  const fiyat = parseFloat(price);
  const oran = Number(yuzde_oran);
  const yuzde = (fiyat * oran) / 100;
  const total = fiyat - yuzde;

  return total;
};
export const kdv_toplam = (price, kdv_oran) => {
  const fiyat = Number(price);
  const kdv = Number(kdv_oran);
  const total = fiyat + (fiyat * kdv) / 100;
  return total;
};

export const exChange = (value, sabit_kur) => {
  if (value > 0) {
    const kurlar = store.getters.getKurListesi;
    const secilen_kur = store.getters.getExchange;

    const sabit_kur_fiyat = kurlar.find((x) => x.banka_kodu === sabit_kur).satis;

    const secilen_kur_fiyat =
      secilen_kur.banka_kodu == 'TRY' ? 1 : kurlar.find((x) => x.banka_kodu === secilen_kur.banka_kodu).satis;

    let fiyat = (parseFloat(value) * parseFloat(sabit_kur_fiyat)) / parseFloat(secilen_kur_fiyat);

    return parseFloat(fiyat);
  } else {
    return 0;
  }
};

export const sabitKuraCevir = (value, sabit_kur, secilen_kur) => {
  if (value > 0) {
    const kurlar = store.getters.getKurListesi;

    const sabit_kur_fiyat = kurlar.find((x) => x.banka_kodu === sabit_kur).satis;

    const secilen_kur_fiyat = secilen_kur.banka_kodu == 'TRY' ? 1 : kurlar.find((x) => x.banka_kodu === secilen_kur).satis;

    let fiyat = (parseFloat(value) * parseFloat(secilen_kur_fiyat)) / parseFloat(sabit_kur_fiyat);

    return parseFloat(fiyat);
  } else {
    return 0;
  }
};

// export default () => {
//   const urun_toplam = (miktar, price) => {
//     const total = price * miktar;
//     return total;
//   };
// };

// export default (context) => {
//   urun_toplam = (miktar, price) => {
//     const total = price * miktar;
//     return total;
//   };
//   kdv_hesapla = (price, kdv_oran) => {
//     const fiyat = Number(price);
//     const kdv = Number(kdv_oran);
//     const total = (fiyat * kdv) / 100;
//     return total;
//   };
//   yuzde_hesapla = (price, yuzde_oran) => {
//     const fiyat = parseFloat(price);
//     const oran = Number(yuzde_oran);
//     const yuzde = (fiyat * oran) / 100;
//     const total = fiyat - yuzde;

//     return total;
//   };
//   kdv_toplam = (price, kdv_oran) => {
//     const fiyat = Number(price);
//     const kdv = Number(kdv_oran);
//     const total = fiyat + (fiyat * kdv) / 100;
//     return total;
//   };
//   exChange = (value, sabit_kur) => {
//     if (value > 0) {
//       const store = context.store;
//       const setting = context.$useSettings;
//       const kurlar = setting.exchange();
//       const secilen_kur = store.getters['app/getExchange'];

//       const sabit_kur_fiyat = kurlar.find((x) => x.banka_kodu === sabit_kur).satis;

//       const secilen_kur_fiyat =
//         secilen_kur.banka_kodu == 'TRY' ? 1 : kurlar.find((x) => x.banka_kodu === secilen_kur.banka_kodu).satis;

//       let fiyat = (parseFloat(value) * parseFloat(sabit_kur_fiyat)) / parseFloat(secilen_kur_fiyat);

//       return parseFloat(fiyat);
//     } else {
//       return 0;
//     }
//   };
//   yarum_puan_hesapla = (yorumlar) => {
//     if (yorumlar.length > 0) {
//       let puanToplam = 0;
//       const toplamYorum = yorumlar.length;
//       yorumlar.forEach((element) => {
//         puanToplam += element.puan;
//       });

//       return parseInt(puanToplam) / parseInt(toplamYorum);
//     }
//   };
// };
